// 
// _fullcalendar.scss
// 


.fc td,
.fc th {
    border: $table-border-width solid $table-border-color;
}

.fc {
    .fc-toolbar {
        h2 {
            font-size: 16px;
            line-height: 30px;
            text-transform: uppercase;
        }

        @media (max-width: 767.98px) {

            .fc-left,
            .fc-right,
            .fc-center {
                float: none;
                display: block;
                text-align: center;
                clear: both;
                margin: 10px 0;
            }

            >*>* {
                float: none;
            }

            .fc-today-button {
                display: none;
            }
        }

        .btn {
            text-transform: capitalize;
        }
    }

    .fc-col-header-cell {
        background-color: var(--#{$prefix}light);
    }

    .fc-col-header-cell-cushion {
        display: block;
        padding: 8px 4px;
        color: var(--#{$prefix}body-color);
    }
    
    .fc-daygrid-day-number {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 12px;
        font-weight: $font-weight-medium;
        margin: 2px;
        color: var(--#{$prefix}body-color);
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);
    
            .fc-daygrid-day-number {
                background-color: $primary;
                color: $white;
            }
        }
    }

    .fc-daygrid-day {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);
        }
    }
    
    .fc-timegrid-col {
        &.fc-day-today {
            background-color: rgba($primary, 0.1);
        }
    }

    .fc-list-event:hover td {
        background: transparent;
    }

    .fc-list-event-title {
        a {
            color: $white !important;
        }
    }

    .fc-col-header,
    .fc-daygrid-body,
    .fc-scrollgrid-sync-table {
        width: 100% !important;
    }

    .fc-scrollgrid-section>*{
        border-left: $table-border-width solid $table-border-color;
        border-top: $table-border-width solid $table-border-color;
    }

    .fc-scrollgrid{
        border: 0;
    }

    .fc-scrollgrid{
        border: 0;
    }

    .fc-scrollgrid-section-liquid>td{
        border-top: 0;
    }
}

.fc-theme-bootstrap a:not([href]) {
    color: var(--#{$prefix}body-color);
}

.fc-event {
    color: $white;
}

.fc {
    th.fc-widget-header {
        background: $gray-300;
        line-height: 20px;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: $font-weight-bold;
    }
}

.fc-unthemed {

    .fc-content,
    .fc-divider,
    .fc-list-heading td,
    .fc-list-view,
    .fc-popover,
    .fc-row,
    tbody,
    td,
    th,
    thead {
        border-color: $gray-300;
    }

    td.fc-today {
        background: lighten($gray-200, 2%);
    }
}

.fc-button {
    background: var(--#{$prefix}secondary-bg);
    border-color: var(--#{$prefix}border-color);
    color: $gray-700;
    text-transform: capitalize;
    box-shadow: none;
    padding: 6px 12px !important;
    height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
    background-color: $primary;
    color: $white;
    text-shadow: none;
}

.fc-event {
    border-radius: 2px;
    border: none;
    cursor: move;
    font-size: 0.8125rem;
    margin: 5px 7px;
    padding: 5px 5px;
    text-align: center;
}

.fc-event,
.fc-event-dot {
    background-color: $primary;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover {
    background-color: $primary;
}

.fc-event-title,
.fc-sticky {
    font-weight: $font-weight-semibold !important;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.fc-daygrid-event-dot {
    border-color: $white !important;
    display: none;
}

.fc-event-time {
    display: none;
}

.fc-event .fc-content {
    color: $white;
}

#external-events {
    .external-event {
        text-align: left;
        padding: 8px 16px;
        margin: 6px 0;
    }
}


.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
    .fc-content {
        color: $light;
    }
}


.fc-prev-button,
.fc-next-button {
    position: relative;
    height: 37.5px;
    width: 37.5px;
    &::before {
        position: absolute;
        font-family: remixicon !important;
        font-size: 20px;
        top: 0;
        bottom: 0px;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
.fc-prev-button {
    &::before {
        content: "\ea64";
    }
}
.fc-next-button {
    &::before {
        content: "\ea6e";
    }
}

.fc-toolbar-chunk {
    .btn-group {
        box-shadow: $element-shadow;
        .btn{
            color: $secondary;
            background-color: rgba($secondary, 0.15);
            border: none;
            box-shadow: none;

            &:hover,
            &.active {
                color: $white;
                background-color: $secondary;
            }
        }
    }

    .fc-today-button {
        background-color: $secondary !important;
        border: $secondary;
    }
}

// .fc {
//     .fc-button-primary:not(:disabled).fc-button-active, 
//     .fc-button-primary:not(:disabled):active{
//         background-color: $secondary !important;
//         border-color: $secondary !important;
//         color: $white !important;
//     }
//     .fc-button-primary {
//         text-transform: capitalize;
//     }
// }


.fc-toolbar {
    @media (max-width: 575.98px) {
        flex-direction: column;
        gap: 16px;
    }
}

#upcoming-event-list {
    .card {
        &:last-child {
            margin-bottom: 6px !important;
        }
    }
}

#event-modal {
    .event-details {
        display: none;
    }

    .view-event {
        .event-form {
            display: none;
        }

        #event-category-tag {
            display: none;
        }

        .event-details {
            display: block;
        }
    }
}

.fc-daygrid-event-harness {
    .fc-daygrid-event {
        backdrop-filter: blur(4px);
    }
}

.fc-timegrid-event-harness {
    .fc-timegrid-event {
        backdrop-filter: blur(4px);
    }
}


.fc-timegrid-slots {
    table {
        tr {
            border-color: var(--#{$prefix}border-color) !important;
        }
    }
}

.fc-list-table {
    border-color: var(--#{$prefix}border-color);
}

@each $color,
$value in $theme-colors {
    .fc-daygrid-event-harness {
        .fc-daygrid-event {
            &.bg-#{$color}-subtle {

                .fc-event-title,
                .fc-event-main {
                    color: $value !important;
                }
            }
        }
    }

    .fc-timegrid-event-harness {
        .fc-timegrid-event {
            &.bg-#{$color}-subtle {
                .fc-event-title {
                    color: $value !important;
                }
            }
        }
    }

    .fc-list-table {
        .fc-list-event {
            &.bg-#{$color}-subtle {
                color: $value !important;

                .fc-list-event-title {
                    >a {
                        color: $value !important;
                    }
                }

                .fc-list-event-dot {
                    border-color: $value;
                }
            }
        }
    }
}

.fc-daygrid-event-harness .fc-daygrid-event.bg-dark-subtle{
    .fc-event-main, .fc-event-title {
        color:  var(--#{$prefix}dark-text-emphasis) !important;
    }
}

.fc-direction-ltr{
    direction: ltr;
    .fc-toolbar>*>:not(:first-child){
        margin-left: 0.75em;
    }
}